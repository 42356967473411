<template>
  <v-card
    class="mx-auto scroll"
    outlined
  >
    <!-- <v-card-title>
      {{$t('FILTERS')}}

      <v-btn
        class="ma-2"
        outlined
        color="indigo"
        @click="$emit('resetFilters')"
      >
        {{$t('COMMON.RESET')}}
      </v-btn>
      <v-btn
        color="success"
        dark
        @click="$emit('applyFilters')"
      >
        {{$t('COMMON.APPLY')}}
      </v-btn>
    </v-card-title> -->

    <v-card-text>
      <v-row>
        <v-col class="pa-2">
          <!--
          <v-select
            ref="where.status"
            v-if="showStatus"
            :value="status"
            :items="statuses"
            :label="$t('INPUT.STATUS')"
            @change="statusChange"
            prepend-inner-icon="mdi-list-status"
            item-text="text"
            item-value="value"
            hide-details
            outlined
          ></v-select>
          <p></p>
          <v-menu
            v-if="showCreatedAt"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('INPUT.CREATED_AT')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="dateMenu = false"
              @change="date => $emit('dateChange', date)"
            ></v-date-picker>
          </v-menu>
          -->
          <slot/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

const _statuses = [ 1, 0, -1 ]

export default {
  name: 'FilterWrapper',
  props: {
    showStatus: {
      type: Boolean,
      default: false,
    },
    showCreatedAt: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateMenu: false,
    date: '',
    status: _statuses[0],
  }),
  computed: {
    statuses () {
      return _statuses.map(value => ({
        value, text: this.$t(`INPUT.STATUS_${value === -1 ? '_1' : value}`),
      }))
    },
  },
  methods: {
    reset () {
      this.date = ''
      this.status = _statuses[0]
    },

    statusChange (status) {
      this.$emit('statusChange', status)
      this.status = status
    },
  },
}
</script>
